import bestPracticeImg from 'assets/icons/footer/best-pratice.webp'
import securityImg from 'assets/icons/footer/security.webp'
import tailorImg from 'assets/icons/footer/tailor.webp'
import patrickImg from 'assets/icons/footer/patrick.png'
import michaelImg from 'assets/icons/footer/michael.svg'
import robertImg from 'assets/icons/footer/robert.svg'
import valerianImg from 'assets/icons/footer/valerian.png'
import searchImg from 'assets/icons/footer/search.svg'
import dollarImg from 'assets/icons/footer/dollar.svg'
import laptopImg from 'assets/icons/footer/laptop.svg'
import quoteImg from 'assets/icons/footer/quote.svg'
import emmy from 'assets/icons/footer/emmy.svg'
import {MIXPANEL_KEYS} from 'analytics/mixpanel'
import {
  ABOUT_US,
  ADDITIONAL_SERVICES,
  APP_MANAGEMENT,
  BECOME_SECURITY_COMPLIANT,
  BLOG,
  CAREERS,
  COOKIES_SETTINGS,
  DEVICE_MANAGEMENT,
  FOR_FOUNDERS_HR_AND_IT_ADMINS,
  HYBR1D_PLATFORM,
  HYBRID_APP,
  IDENTITY_MANAGEMENT,
  DEMO_LINK,
  PERSONAL_DATA_PROCESSING_AGREEMENT,
  PRIVACY_POLICY,
  SAVE_COSTS_AND_ESTABLISH_SCALABLE_PROCESSES,
  TERMS_OF_SERVICE,
  WHY_HYBR1D,
} from 'utils/getters'
import {IThreeCardSection} from 'types/common'

export const extraFooterData: IThreeCardSection = {
  topTitle: 'TRANSPARENCY & COST EFFICIENCY',
  title:
    'Managed Service Providers and in-house IT admins can’t give you the ease of use, transparency and cost efficiency we can',
  desc: '',
  card: {
    title: 'Tailor-made for cloud-native companies to reduce IT workload',
    subTitle:
      'Securely manage company devices from one place. Streamline setup, reduce admin tasks, and stay compliant. Manage any device, anywhere with our feature.',
    img: tailorImg,
  },
  bottomCard: [
    {
      title: 'We ensure industry best-practices',
      desc: 'Ensure that only authorized users can access company resources and data while reducing administrative overhead and improving productivity for IT Admins & HR.',
      img: bestPracticeImg,
    },
    {
      title: 'Security & compliance by design',
      desc: 'Your one-stop shop for your IT Administration & Security workflows along the employee lifecycle.',
      img: securityImg,
    },
  ],
}

export const footerTestimonialData = {
  topTitle: 'Our clients',
  title: 'Loved by Founders, Admins & HR',
  desc: 'Your one-stop shop for IT Administration & Security workflows along the employee lifecycle.',
  cards: [
    {
      quoteImg: quoteImg,
      text: 'I only regret not outsourcing this topic earlier, hiring ZenAdmin has taken away so much of my headache. What I value most is that we have been able to streamline IT administration processes, remove IT security risks and our internal resources can now concentrate fully on our business again.”',
      img: valerianImg,
      position: 'Valerian Seither, Co-Founder at <b>emmy </b>',
      tagText: ['Electric Mobility Provider', '110 Employees', 'Series-D Acquired'],
    },
    {
      quoteImg: quoteImg,
      text: 'ZenAdmin quickly helped us to get tiresome IT Ops processes off my table by taking over IT onboarding & offboarding, application provisioning, and device security. This frees up many hours to focus on our clients.”',
      img: patrickImg,
      position: 'Patrick Kern, CTO at <b>brighter AI </b>',
      positionImg: '',
      tagText: ['Deep Learning Anonymization', '41 Employees', 'Series-A'],
    },
    {
      quoteImg: quoteImg,
      text: `ZenAdmin automates many of the manual tasks that used to be time-consuming and prone to errors. This has not only saved my team a lot of time but also improved the overall efficiency. Other than that we have been very impressed with ZenAdmin's impeccable IT support.”`,
      img: michaelImg,
      position: 'Michael Hart, CTO at <b> Sleek </b>',
      positionImg: '',
      tagText: ['Accounting', '500 Employees', 'Series-A'],
    },
    {
      quoteImg: quoteImg,
      text: `From the moment we started using ZenAdmin, we knew we had made the right choice. The platform's intuitive interface has made it easy for us to procure and keep track of our device inventory. ZenAdmin helped us empower our global workforce to become productive from day 1.”`,
      img: robertImg,
      position: 'Robert Cook, IT Manager at <b> beam </b>',
      positionImg: '',
      tagText: ['Micromobility', '29 Employees', 'Series-B'],
    },
  ],
}

export const footerManagementData = {
  title: 'ZenAdmin: We make IT easy',
  desc: 'Use ZenAdmin to centralize IT administration & security management – so nothing gets in the way of your moving up and winning big.',
  cards: [
    {
      title: 'See ZenAdmin platform in action',
      btnText: 'Schedule a free demo',
      btnUrl: DEMO_LINK,
      icon: laptopImg,
      bgColor:
        'linear-gradient(218deg, rgba(25, 22, 83, 0.50) 3.57%, rgba(97, 136, 187, 0.30) 49.44%, rgba(97, 136, 187, 0.30) 101.59%)',
    },
    {
      title: 'Get free process evaluation without commitment',
      btnText: 'Book a free consultation',
      btnUrl: DEMO_LINK,
      icon: searchImg,
      bgColor:
        'linear-gradient(197deg, rgba(38, 29, 125, 0.76) 7.3%, rgba(26, 200, 209, 0.30) 55.73%, rgba(26, 200, 209, 0.30) 93.41%)',
    },
    {
      title: 'Get in touch with us for a customized quote',
      btnText: 'Request a quote',
      btnUrl: 'mailto:sales@zenadmin.ai?subject=Request%20Quote',
      icon: dollarImg,
      bgColor:
        'linear-gradient(197deg, #281F86 7.3%, rgba(2, 94, 102, 0.60) 49.77%, rgba(2, 94, 102, 0.60) 93.41%)',
    },
  ],
}

export const footerServiceData = {
  inputLabel:
    'Sign up for a free consulting call. Let’s see if we can help you with your IT management.',
  btnText: 'Sign up',
  btnLink: HYBRID_APP,
  lists: [
    {
      title: 'Products',
      list: [
        {name: 'ZenAdmin Platform', link: HYBR1D_PLATFORM},
        {name: 'Identity Management', link: IDENTITY_MANAGEMENT},
        {name: 'Access Management', link: APP_MANAGEMENT},
        {name: 'Device Management', link: DEVICE_MANAGEMENT},
        {name: 'Additional Services', link: ADDITIONAL_SERVICES},
      ],
    },
    {
      title: 'Solutions',
      list: [
        {name: 'Become security compliant', link: BECOME_SECURITY_COMPLIANT},
        {
          name: 'Save costs & establish scalable processes',
          link: SAVE_COSTS_AND_ESTABLISH_SCALABLE_PROCESSES,
        },
        {name: 'For Founders, HR, and IT Admins', link: FOR_FOUNDERS_HR_AND_IT_ADMINS},
      ],
    },
    {
      title: 'About us',
      list: [
        {name: 'Company', link: ABOUT_US},
        {name: 'About us', link: ABOUT_US},
        {name: 'Blog', link: BLOG},
        {name: 'Career', link: CAREERS},
        {name: 'Why ZenAdmin', link: WHY_HYBR1D},
      ],
    },
  ],
}

export const footerOfficeAddress = [
  {
    name: 'Germany',
    address: ['Leibnizstraße 58,', '10629 Berlin,', 'DEHRB236794B'],
  },
  {
    name: 'India',
    address: ['HD-128, 43, WeWork Galaxy', 'Ashok Nagar, Bengaluru', '560025'],
  },
  {
    name: 'Singapore',
    address: ['160 Robinson Rd #14-04', 'Singapore - 068914'],
  },
  {
    name: 'Hong Kong',
    address: ['Unit 2A 17/F,', 'Glenealy Tower No. 1', 'Glenealy Central,', 'HK - 999077'],
  },
  {
    name: 'Texas',
    address: ['25298 FM 2978 RD UNIT A,', 'TOMBALL, TX,', '77375-2504,', 'UNITED STATES'],
  },
  {
    name: 'State of Washington',
    address: ['4505 PACIFIC HWY E STE C-2,', 'FIFE, WA,', '98424,', 'UNITED STATES'],
  },
]

export const subFooterLinks = [
  {
    title: 'Privacy Policy',
    href: PRIVACY_POLICY,
    eventKey: MIXPANEL_KEYS.PRIVACY_POLICY,
  },
  {
    title: 'Terms of Service',
    href: TERMS_OF_SERVICE,
    eventKey: MIXPANEL_KEYS.TERMS_OF_SERVICE,
  },
  // {
  //   title: 'Personal Data Processing Agreement',
  //   href: PERSONAL_DATA_PROCESSING_AGREEMENT,
  //   eventKey: MIXPANEL_KEYS.PERSONAL_DATA_PROCESSING_AGREEMENT,
  // },
]

export const faqData = {
  topTitle: 'Our clients',
  title: 'Any questions left?',
  faqs: [
    {
      question: 'How do I set up a new employee?',
      ans: `In your organization's Identity and Access Management system, setting up a new employee entails creating a digital identity for the person and adding them to the necessary access groups. You would also provision access to necessary applications. Additionally, you should pre-configure the device(s) of the employee with the required settings and programs.`,
    },
    {
      question: 'How long does it take to implement?',
      ans: 'Implementation takes between 2 and 4 weeks.',
    },
    {
      question: 'How is the data secured?',
      ans: 'Data security is achieved through a combination of strong identity and access management rules, multi-factor authentication, encryption, and security controls.',
    },
    {
      question: 'How do you ensure in an offboarding that all accounts and accesses are revoked?',
      ans: `The offboarding process is automated and designed to securely manage the exit of an employee. This includes the revocation of all application access and all access rights, ensuring that the former employee can no longer access company resources. Additionally, any company data on the employee's devices would be securely erased.`,
    },
    {
      question:
        'Does ZenAdmin support Mobile Device Management (MDM) for both Mac and Windows devices?',
      ans: 'ZenAdmin provides an effective Mobile Device Management (MDM) solution that caters to both Mac and Windows devices.',
    },
  ],
}
