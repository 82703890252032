'use client'
import React from 'react'
import {hasCookie, setCookie} from 'cookies-next'
import classes from './styles.module.css'
import {PRIVACY_POLICY} from 'utils/getters'
import Image from 'next/image'
import cookieImg from 'assets/icons/common/cookie.svg'

const CookieConsent = () => {
  const [showConsent, setShowConsent] = React.useState(true)

  React.useEffect(() => {
    setShowConsent(hasCookie('localConsent'))
  }, [])

  const acceptCookie = () => {
    setShowConsent(true)
    setCookie('localConsent', 'true', {})
  }

  if (showConsent) {
    return null
  }

  return (
    <div className={classes.parentContainer}>
      <div className={classes.container}>
        <Image src={cookieImg} alt="cookie" className={classes.cookieImg} />
        <div>
          This website uses cookies to improve user experience. By using our website you consent to
          all cookies in accordance with our <a href={PRIVACY_POLICY}>Cookie Policy</a> .
        </div>
        <button className={classes.button} onClick={() => acceptCookie()}>
          Accept
        </button>
      </div>
    </div>
  )
}

export default CookieConsent
