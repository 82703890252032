import mixpanel, {Query, Dict} from 'mixpanel-browser'

const isProd = process.env.NODE_ENV === 'production'
const MIXPANEL_TOKEN = isProd
  ? process.env.NEXT_PUBLIC_MIXPANEL_TOKEN_PROD
  : process.env.NEXT_PUBLIC_MIXPANEL_TOKEN_DEV

mixpanel.init(MIXPANEL_TOKEN as string, {debug: false})

export const Mixpanel = {
  identify: (id: string | undefined) => {
    mixpanel.identify(id)
  },
  alias: (id: string) => {
    mixpanel.alias(id)
  },
  track: (name: string, props = {}) => {
    mixpanel.track(name, props)
  },
  track_links: (query: Query, name: string) => {
    mixpanel.track_links(query, name, {
      referrer: document.referrer,
    })
  },
  people: {
    set: (props: Dict) => {
      mixpanel.people.set(props)
    },
  },
}

export const MIXPANEL_KEYS = {
  HOMEPAGE: 'HOMEPAGE',

  // Solutions
  ONBOARDING: 'ONBOARDING',
  LIFECYCLE_MANAGEMENT: 'LIFECYCLE_MANAGEMENT',
  OFFBOARDING: 'OFFBOARDING',

  // PRODUCTS
  DATA_MANAGEMENT: 'DATA_MANAGEMENT',
  WORKLFOW_AUTOMATION: 'WORKLFOW_AUTOMATION',
  IT_SUPPORT: 'IT_SUPPORT',
  REPORTING_ANALYTICS: 'REPORTING_ANALYTICS',
  LEAVE_ATTENDANCE_TRACKING: 'LEAVE_ATTENDANCE_TRACKING',
  DEVICE_RETRIEVAL_INVENTORY_MANAGEMENT: 'DEVICE_RETRIEVAL_INVENTORY_MANAGEMENT',
  DEVICE_MANAGEMENT_SECURITY: 'DEVICE_MANAGEMENT_SECURITY',
  DEVICE_PROCUREMENT_PROVISIONING: 'DEVICE_PROCUREMENT_PROVISIONING',
  SAAS_MANAGEMENT: 'SAAS_MANAGEMENT',

  // OTHERS
  ABOUT_US: 'ABOUT_US',
  TERMS_OF_SERVICE: 'TERMS_OF_SERVICE',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  PERSONAL_DATA_PROCESSING_AGREEMENT: 'PERSONAL_DATA_PROCESSING_AGREEMENT',
  404: '404',
  CONTACT_US: 'CONTACT_US',
  THANK_YOU: 'THANK_YOU',

  // Buttons
  BOOK_A_DEMO: 'BOOK_A_DEMO',
  USE_HYBR1D_PLATFORM: 'USE_HYBR1D_PLATFORM',
  SUBSCRIBE: 'SUBSCRIBE',
  CONTACT_US_SUBMIT: 'CONTACT_US_SUBMIT',

  // Assets
  WATCH_PRODUCT_TOUR_VIDEO: 'WATCH_PRODUCT_TOUR_VIDEO',

  // External Links
  BLOG_POST: 'BLOG_POST',
  BLOG: 'BLOG',
  CAREERS: 'CAREERS',
  FRESHSTATUS: 'FRESHSTATUS',
  TWITTER: 'TWITTER',
  LINKEDIN: 'LINKEDIN',

  // Misc
  NAVBAR: 'NAVBAR',
  FOOTER: 'FOOTER',
}

export const MIXPANEL_ATTRIBUTES = {
  SOURCE: 'source',
  BLOG_TITLE: 'blog_title',
  EMAIL: 'email',
  FULL_NAME: 'full_name',
  PHONE_NUMBER: 'phone_number',
  COUNTRY_NAME: 'country_name',
  MESSAGE: 'message',
  BLOG_URL: 'blog_url',
}
